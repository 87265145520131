<template>
  <div class="question-container">
    <p class="no-regions-text">
      If no regions are affected, please click this button:
      <button
        class="no-regions-button"
        :class="{ 'no-regions-active': noRegionsButton }"
        @click="toggleNoRegions"
      >
        No Regions Affected
      </button>
    </p>
    <div
      :class="{
        'diagram-container': true,
        'column-container': expanded_view,
        'disabled-diagram': noRegionsButton,
      }"
    >
      <div v-if="noRegionsButton" class="overlay-text">No Regions Affected</div>
      <div
        :class="{
          'aspect-ratio-preserver': true,
          'aspect-ratio-preserver-expanded': expanded_view,
        }"
      >
        <div class="svg-container">
          <MannequinDiagramFront
            class="human-diagram"
            :selectedRegions="localSelectedRegions['front']"
            :obj_regions="obj_regions"
            :obj_regions_backup="obj_regions_backup"
            @update-regions="(regions) => selectRegion(regions, 'front')"
            @genitals-selected="genitalsSelected"
          />
        </div>
      </div>
      <div
        :class="{
          'aspect-ratio-preserver': true,
          'aspect-ratio-preserver-expanded': expanded_view,
        }"
      >
        <div class="svg-container">
          <MannequinDiagramBack
            class="human-diagram"
            :selectedRegions="localSelectedRegions['back']"
            :obj_regions="obj_regions"
            :obj_regions_backup="obj_regions_backup"
            @update-regions="(regions) => selectRegion(regions, 'back')"
          />
        </div>
      </div>
      <!-- Don't show expand button for results page -->
      <div
        v-if="!expanded_view"
        class="expand_icon"
        @click="expanded_view = true"
      >
        <img src="@/assets/expand_icon.svg" alt="Expand" />
      </div>
      <div
        v-if="expanded_view"
        class="expand_icon"
        @click="expanded_view = false"
      >
        <img src="@/assets/minimize_icon.svg" alt="Expand" />
      </div>
    </div>
  </div>
</template>

<script>
import MannequinDiagramBack from "@/view/pages/MannequinDiagramBack.vue";
import MannequinDiagramFront from "@/view/pages/MannequinDiagramFront.vue";

const NUM_FRONT_REGIONS = 23;
const NUM_BACK_REGIONS = 22;
const idToRegion = new Map([
  // these region names are linked to those accepted in the backend
  // must be changed with the backend accordingly
  // -- front diagram --
  [0, "Head"], // scalp
  [1, "Head"], // face
  [2, "Head"], // neck
  [3, "Upper Limbs"], // right_shoulder
  [4, "Upper Limbs"], // right_elbow
  [5, "Upper Limbs"], // right_forearm
  [6, "Upper Limbs"], // right_hand
  [7, "Upper Limbs"], // left_shoulder
  [8, "Upper Limbs"], // left_elbow
  [9, "Upper Limbs"], // left_forearm
  [10, "Upper Limbs"], // left_hand
  [11, "Trunk"], // body_up
  [12, "Trunk"], // body_down
  [13, "Trunk"], // belly
  [14, ""], // genitals
  [15, "Lower Limbs"], // right_thigh
  [16, "Lower Limbs"], // right_knee
  [17, "Lower Limbs"], // right_calf
  [18, "Lower Limbs"], // right_foot
  [19, "Lower Limbs"], // left_thigh
  [20, "Lower Limbs"], // left_knee
  [21, "Lower Limbs"], // left_calf
  [22, "Lower Limbs"], // left_foot
  // -- back diagram --
  [23, "Head"], // scalp
  [24, "Head"], // head
  [25, "Upper Limbs"], // right_shoulder
  [26, "Upper Limbs"], // right_elbow
  [27, "Upper Limbs"], // right_forearm
  [28, "Upper Limbs"], // right_hand
  [29, "Upper Limbs"], // left_shoulder
  [30, "Upper Limbs"], // left_elbow
  [31, "Upper Limbs"], // left_forearm
  [32, "Upper Limbs"], // left_hand
  [33, "Trunk"], // body_up
  [34, "Trunk"], // body_down
  [35, "Trunk"], // lower_back
  [36, "Trunk"], // bum
  [37, "Lower Limbs"], // right_thigh
  [38, "Lower Limbs"], // right_knee
  [39, "Lower Limbs"], // right_calf
  [40, "Lower Limbs"], // right_foot
  [41, "Lower Limbs"], // left_thigh
  [42, "Lower Limbs"], // left_knee
  [43, "Lower Limbs"], // left_calf
  [44, "Lower Limbs"], // left_foot
]);

export default {
  name: "Mannequin",
  components: { MannequinDiagramFront, MannequinDiagramBack },
  props: {
    selectedRegionKeys: {
      type: Array,
      default: () => [],
    },
    // passing the data from ImageUpload.vue to MannequinDiagramFront.vue and MannequinDiagramBack.vue
    mannequin_selected_regions: {
      type: Object,
    },
    // passing the data from ImageUpload.vue to MannequinDiagramFront.vue and MannequinDiagramBack.vue
    mannequin_selected_regions_backup: {
      type: Object,
    },
    mannequin_no_region: { type: Boolean },
  },
  emits: [
    "update",
    "update-regions",
    "selected-regions",
    "toggle-no-region",
    "genitals-selected",
  ],
  data() {
    return {
      noRegionsButton: this.selectedRegionKeys.includes(-1),
      localSelectedRegions: this.keysToRegions(this.selectedRegionKeys),
      savedSelectedRegions: this.keysToRegions([]),
      expanded_view: false,
    };
  },
  computed: {
    regionKeys() {
      return this.noRegionsButton
        ? [-1]
        : this.regionsToKeys(this.localSelectedRegions);
    },
    regionNames() {
      return this.selectedRegionNames(this.regionKeys);
    },
    // passing the data from ImageUpload.vue to MannequinDiagramFront.vue and MannequinDiagramBack.vue
    obj_regions: function () {
      if (this.mannequin_no_region) {
        // if no regions button is clicked, then no regions on the mannequin shouldn't be highlighted
        return { obj_regions: {} };
      }
      return { obj_regions: this.mannequin_selected_regions };
    },
    // passing the data from ImageUpload.vue to MannequinDiagramFront.vue and MannequinDiagramBack.vue
    obj_regions_backup: function () {
      if (this.mannequin_no_region) {
        // if no regions button is clicked, then no regions on the mannequin shouldn't be highlighted
        return { obj_regions_backup: {} };
      }
      return {
        obj_regions_backup: this.mannequin_selected_regions_backup,
      };
    },
  },
  watch: {
    mannequin_selected_regions(newValue) {
      if (Object.keys(newValue).length > 0) {
        this.localSelectedRegions = newValue;
      } else {
        this.localSelectedRegions = {
          front: new Array(NUM_FRONT_REGIONS).fill(false),
          back: new Array(NUM_BACK_REGIONS).fill(false),
        };
      }
    },
    mannequin_selected_regions_backup(newValue) {
      if (Object.keys(newValue).length > 0) {
        this.savedSelectedRegions = newValue;
      } else {
        this.savedSelectedRegions = {};
      }
    },
    mannequin_no_region(newValue) {
      if (newValue) {
        this.noRegionsButton = true;
        this.localSelectedRegions = {};
        this.emitUpdate();
      }
    },
  },
  methods: {
    emitUpdate() {
      this.$emit("update", {
        selectedOption: this.regionKeys,
      });
      this.$emit("update-regions", this.regionNames);
      this.$emit("selected-regions", this.localSelectedRegions);
      this.$emit("toggle-no-region", this.noRegionsButton);
      this.$emit("genitals-selected", this.regionNames.has("Genitals"));
    },
    keysToRegions(keys) {
      const regions = {
        front: new Array(NUM_FRONT_REGIONS).fill(false),
        back: new Array(NUM_BACK_REGIONS).fill(false),
      };

      if (keys.includes(-1)) {
        return regions;
      }
      keys.forEach((key) => {
        if (key < NUM_FRONT_REGIONS) {
          regions.front[key] = true;
        } else {
          regions.back[key - NUM_FRONT_REGIONS] = true;
        }
      });
      return regions;
    },
    regionsToKeys(regions) {
      const keys = [];
      regions.front.forEach((region, index) => {
        if (region) keys.push(index);
      });
      regions.back.forEach((region, index) => {
        if (region) keys.push(index + NUM_FRONT_REGIONS);
      });
      return keys;
    },
    toggleNoRegions() {
      // if user is clicking "No Regions Affected"
      if (!this.noRegionsButton) {
        this.noRegionsButton = true;
        this.savedSelectedRegions = this.localSelectedRegions;
        this.localSelectedRegions = {};
      } else {
        this.noRegionsButton = false;
        this.localSelectedRegions = this.savedSelectedRegions;
      }
      this.emitUpdate();
    },
    selectRegion(regions, side) {
      this.savedSelectedRegions = this.localSelectedRegions;
      this.localSelectedRegions = {};
      this.localSelectedRegions = this.savedSelectedRegions;
      this.localSelectedRegions[side] = regions;
      this.emitUpdate();
      return;
    },
    selectedRegionNames(regionKeys) {
      if (regionKeys.includes(-1)) {
        return new Set();
      } else {
        let selectedRegionNames = regionKeys.map((id) => idToRegion.get(id));
        // remove empty strings (genitals) (or if no regions button selected: -1)
        // (coded this way to avoid accidentally asking for genitals in the image upload section)
        selectedRegionNames = selectedRegionNames.filter((name) => name);
        return new Set(selectedRegionNames);
      }
    },
    genitalsSelected(value) {
      // this seems to change to true only the Front Mannequin is clicked
      // but it becomes false when the back mannequin is clicked
      // so it's not really useful
      this.$emit("genitals-selected", value);
    },
  },
};
</script>

<style>
.question-container {
  width: 90%;
  padding-bottom: 3em;
  font-family: "Roboto", sans-serif;
  text-align: left;
  margin: auto;
}

.diagram-container {
  position: relative;
  box-sizing: border-box;
  padding: 1em;
  border: 15px solid #e0e0e0;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 auto;  Center the container */
  /* margin-left: 1.5em; */
  margin: auto;
}

.column-container {
  flex-direction: column;
}

.disabled-diagram {
  pointer-events: none; /* Make it non-interactable */
  opacity: 0.3; /* Make it appear "greyed out" */
}

.no-regions-text {
  /* font-size: 1.2em; */
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
  font-family: "Roboto", sans-serif;
}

.no-regions-button {
  border: 1px solid #686868;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  background: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 4px;
  border-radius: 8px;
  width: 200px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.no-regions-button:hover {
  background-color: var(--affected-region-hover-color);
}

.no-regions-button:active {
  background-color: var(--affected-region-color);
  color: white;
}

.no-regions-active {
  background-color: var(--affected-region-color);
  color: white;
}

.no-regions-active:hover {
  background-color: var(--affected-region-color);
  color: white;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  font-size: 2em;
  color: #000;
  text-align: center;
  background-color: white;
}

.expand_icon {
  position: absolute;
  cursor: pointer;
  top: 2em;
  right: 2em;
  width: 2em;
  /* margin: 10px; */
}

.expand_icon img {
  width: 100%;
}

.expand_icon:hover {
  opacity: 0.7;
}

.aspect-ratio-preserver {
  position: relative;
  width: 40%;
  padding-top: 90%; /* Adjust the padding value for different aspect ratios */
}

.aspect-ratio-preserver-expanded {
  width: 100%;
  padding-top: 200%;
}

.svg-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* Now the SVG will resize to fit the container, preserving aspect ratio */
}

.human-diagram {
  width: 100%;
  height: 100%;
}

.question-text {
  margin-bottom: 10px;
  font-size: 1em;
}

@media (max-width: 600px) {
  .question-container {
    margin: 0;
    width: 100%;
  }

  .diagram-container {
    margin: auto;
  }

  .no-regions-button {
    margin-top: 10px;
  }
}

/* styles for the children components DiagramFront and DiagramBack */

.affected-region {
  fill: var(--affected-region-color);
  stroke: var(--affected-region-stroke);
  stroke-width: 1px;
  cursor: pointer;
}

.affected-region:hover {
  fill: var(--affected-region-hover-color);
}

.non-affected-region {
  fill: var(--non-affected-region-color);
  cursor: pointer;
}

.non-affected-region:hover {
  fill: var(--affected-region-hover-color);
}

/* This will only apply when the device does not support hovering (like most touchscreens) */
@media (hover: none) {
  .affected-region:hover {
    fill: var(--affected-region-color);
  }
  .non-affected-region:hover {
    fill: var(--non-affected-region-color);
  }
}

:root {
  /* Buttons */
  --activated-button-color: #064896;
  --hover-color: #4f9eff3b;
  --selected-color: #4f9eff6e;

  /* Human Diagram Question */
  --affected-region-color: #ff5555;
  --affected-region-stroke: #ffb380;
  --affected-region-hover-color: rgb(255, 197, 197);
  --non-affected-region-color: #f0f7ff;
}
</style>
