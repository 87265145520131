var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question-container"},[_c('p',{staticClass:"no-regions-text"},[_vm._v(" If no regions are affected, please click this button: "),_c('button',{staticClass:"no-regions-button",class:{ 'no-regions-active': _vm.noRegionsButton },on:{"click":_vm.toggleNoRegions}},[_vm._v(" No Regions Affected ")])]),_c('div',{class:{
      'diagram-container': true,
      'column-container': _vm.expanded_view,
      'disabled-diagram': _vm.noRegionsButton,
    }},[(_vm.noRegionsButton)?_c('div',{staticClass:"overlay-text"},[_vm._v("No Regions Affected")]):_vm._e(),_c('div',{class:{
        'aspect-ratio-preserver': true,
        'aspect-ratio-preserver-expanded': _vm.expanded_view,
      }},[_c('div',{staticClass:"svg-container"},[_c('MannequinDiagramFront',{staticClass:"human-diagram",attrs:{"selectedRegions":_vm.localSelectedRegions['front'],"obj_regions":_vm.obj_regions,"obj_regions_backup":_vm.obj_regions_backup},on:{"update-regions":(regions) => _vm.selectRegion(regions, 'front'),"genitals-selected":_vm.genitalsSelected}})],1)]),_c('div',{class:{
        'aspect-ratio-preserver': true,
        'aspect-ratio-preserver-expanded': _vm.expanded_view,
      }},[_c('div',{staticClass:"svg-container"},[_c('MannequinDiagramBack',{staticClass:"human-diagram",attrs:{"selectedRegions":_vm.localSelectedRegions['back'],"obj_regions":_vm.obj_regions,"obj_regions_backup":_vm.obj_regions_backup},on:{"update-regions":(regions) => _vm.selectRegion(regions, 'back')}})],1)]),(!_vm.expanded_view)?_c('div',{staticClass:"expand_icon",on:{"click":function($event){_vm.expanded_view = true}}},[_c('img',{attrs:{"src":require("@/assets/expand_icon.svg"),"alt":"Expand"}})]):_vm._e(),(_vm.expanded_view)?_c('div',{staticClass:"expand_icon",on:{"click":function($event){_vm.expanded_view = false}}},[_c('img',{attrs:{"src":require("@/assets/minimize_icon.svg"),"alt":"Expand"}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }